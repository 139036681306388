import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/codebuild/output/src430903205/src/dashbot-home-gatsby/src/layouts/blog/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`We analyzed the ratings of Alexa Skills to get a better understanding of how they compare.`}</p>
    <p>{`There are more than 60,000 Alexa Skills in the Amazon Store. Of those, nearly 70% have no ratings at all.`}</p>
    <p>{`At first glance, there are two significant spikes of one-star and five-star ratings. However, the majority of these are from one review only. In fact, nearly 76% of the one-star ratings and 64% of the five-star ratings are from one review.`}</p>
    <p><img parentName="p" {...{
        "src": "https://i.dashbot.io/wp-home/uploads/2019/07/10113616/ratings-all.png",
        "alt": null
      }}></img>{` If we remove all the Skills with only one review, a broader distribution starts to appear. However, there are still significant spikes at the one-star and five-star ratings. In fact, 96% of one-star ratings come from three or less reviews and 96% of five-star ratings come from five or less reviews.`}</p>
    <p><img parentName="p" {...{
        "src": "https://i.dashbot.io/wp-home/uploads/2019/07/10113639/ratings-1.png",
        "alt": null
      }}></img>{` Examining the ratings even closer reveals 95% of Skills have ten or less reviews. If we remove those Skills, a much clearer picture emerges – with a bell curve in the high threes.`}</p>
    <p><img parentName="p" {...{
        "src": "https://i.dashbot.io/wp-home/uploads/2019/07/10113704/ratings-10.png",
        "alt": null
      }}></img>{` ### Popular Categories`}</p>
    <p>{`The most common category of Skills continues to be Games, followed by Education, Lifestyle, and Music.`}</p>
    <p><img parentName="p" {...{
        "src": "https://i.dashbot.io/wp-home/uploads/2019/07/10113745/skills.png",
        "alt": null
      }}></img>{` The most common category of Skills continues to be Games, followed by Education, Lifestyle, and Music.`}</p>
    <p>{`While games are quite common, our `}<a parentName="p" {...{
        "href": "https://www.dashbot.io/2018/11/19/how-consumers-really-use-alexa-and-google-home-voice-assistants/"
      }}>{`survey of voice device owners`}</a>{` indicated only a small percentage actually use them, at 26%. Those who do, however, tend to use them more frequently with 44% saying they play multiple times a day.`}</p>
    <p>{`Music, on the other hand, is not only one of the more common categories, it is also one of the most popular. Our `}<a parentName="p" {...{
        "href": "https://www.dashbot.io/2018/11/19/how-consumers-really-use-alexa-and-google-home-voice-assistants/"
      }}>{`survey`}</a>{` showed listening to Music was the most common activity at 75% of users.`}</p>
    <p>{`In regards to ratings, Business and Music tend to have the highest ratings at just over 4 stars each, while Smart Home and Utilities are on the low end at about 3 stars on average.`}</p>
    <p><img parentName="p" {...{
        "src": "https://i.dashbot.io/wp-home/uploads/2019/07/10113807/categories-standard.png",
        "alt": null
      }}></img>{` One area we looked into deeper in our survey was `}<a parentName="p" {...{
        "href": "https://www.dashbot.io/2018/12/19/examining-consumer-purchasing-behavior-through-alexa-and-google-home/"
      }}>{`consumer purchasing behavior`}</a>{` through voice assistants. At the time we learned about 43% of users have made a purchase through their device, and 60% are likely to in the future. Looking at the ratings, the Shopping category gets about 3.75 starts on average – it’s one of the top categories.`}</p>
    <h3>{`Recommended Categories`}</h3>
    <p>{`In addition to the standard categories, Alexa has a variety of other types including overall top skills, new skills, and skills for a particular occasion or activity. The skills to help people relax tend to be some of the highest rated at 4.35 stars – perhaps quite effective if you are looking to relax. The Alexa team’s own staff picks tend to fair pretty well at just over 4 stars on average as well.`}</p>
    <p><img parentName="p" {...{
        "src": "https://i.dashbot.io/wp-home/uploads/2019/07/10113824/categories-alexa.png",
        "alt": null
      }}></img>{` ### Importance of Ratings`}</p>
    <p>{`User acquisition and discover are still challenges in this space. We found through our `}<a parentName="p" {...{
        "href": "https://www.dashbot.io/2018/11/19/how-consumers-really-use-alexa-and-google-home-voice-assistants/"
      }}>{`survey`}</a>{` that users often do not even know that these voice apps are called “Skills.” Having higher ratings can help you with discovery – whether the user is asking on the device or looking through the store on the web.`}</p>
    <p>{`To help improve the ratings, it’s important to continually monitor the analytics and take action as necessary to improve the user experience. Dashbot can help you optimize the response effectiveness and increase user satisfaction, engagement, and conversions through actionable insights and tools.`}</p>
    <h3>{`About Dashbot`}</h3>
    <p><a parentName="p" {...{
        "href": "https://www.dashbot.io"
      }}><em parentName="a">{`Dashbot`}</em></a>{` `}<em parentName="p">{`is a conversational analytics platform that enables enterprises and developers to increase engagement, acquisition, and conversion through actionable insights and tools.`}</em></p>
    <p><em parentName="p">{`In addition to traditional analytics like engagement and retention, we provide chatbot specific metrics including NLP response effectiveness, sentiment analysis, conversational analytics, and the full chat session transcripts.`}</em></p>
    <p><em parentName="p">{`We also have tools to take action on the data, like our live person take over of chat sessions and push notifications for re-engagement.`}</em></p>
    <p><em parentName="p">{`We support Alexa, Google Home, Facebook Messenger, Slack, Twitter, Kik, SMS, web chat, and any other conversational interface.`}</em></p>
    <p><a parentName="p" {...{
        "href": "https://www.dashbot.io/contact/sales?ref="
      }}>{`Contact us for a demo`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      